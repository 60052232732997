<template>

  <main ref="resumen" id="resumen">
    <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
    <div class="py-2"><indexHistoriaMenstrualShow :idHistoria="idHistoria" :idUsuario="idUsuario"></indexHistoriaMenstrualShow></div>


   
  </main>

</template>
<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import indexHistoriaMenstrualShow from "../../base/baseHistoriaMenstrual/indexHistoriaMenstrualShow";

export default {
  props: ['idHistoria','idUsuario', 'usuario'],
  components:{
    motivoConsultaShow,
    indexHistoriaMenstrualShow 
  },
}
</script>
<style scoped>
.resumen-container {
  height:300px;
  overflow-y:scroll
}

</style>