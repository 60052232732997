<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact>Historia Clinica - Citologia</page-header-compact>
    <br />
    <div class="container-xl px-4 mt-n15">
      <div class="card ">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <br />
      <wizard
        steps="4"
        @prev="prev($event)"
        @next="next($event)"
        :current_step="current"
      >
        <template #tabs>
          <wizard-tab
            title="Motivo Consulta"
            step="1"
            active="true"
            id="step1"
            v-on:tab-click="next({ prev: current, current: 1 })"
          ></wizard-tab>
         
          <wizard-tab
            title="Historial Menstrual"
            step="2"
            id="step2"
            v-on:tab-click="next({ prev: current, current: 2 })"
          ></wizard-tab>
          <wizard-tab
            title="Toma de Muestras"
            step="3"
            id="step3"
            v-on:tab-click="next({ prev: current, current: 3 })"
          ></wizard-tab>
         
          <wizard-tab
            title="Resumen"
            step="4"
            id="step4"
            v-on:tab-click="next({ prev: current, current: 4 })"
          ></wizard-tab>
        </template>
        <template #tab-content>
          <wizard-tab-pane id="step1" active="true">
            <template #content>
              <component
                :is="currentComponent"
                :ref="currentComponent"
                v-bind="currentProperties"
              ></component>
            </template>
          </wizard-tab-pane>
        </template>
        <template #finish>
          <div class="row">
            <remision-component :idHistoria="idHistoria"></remision-component>
            <finalizar-historia :id-usuario="usuario.id" :id-historia="idHistoria"></finalizar-historia>
          </div>
        </template>
      </wizard>
    </div>
  </main>
</template>
<script>

import Wizard from "../../../components/common/utilities/Wizard/Wizard";
import WizardTab from "../../../components/common/utilities/Wizard/WizardTab";
import WizardTabPane from "../../../components/common/utilities/Wizard/WizardTabPane";
import motivoConsultaComponent from "../base/motivoConsulta/motivoConsulta";
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import historiaClinicaService from "../../../services/historiaClinicaService";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import resumenHcComponent from "./resumenHc/resumenHcComponent";
import historialMenstrualComponent from "../base/baseHistoriaMenstrual/indexHistoriaMenstrual";
import intervencionesComponent from "../base/intervenciones/intervenciones";
import recomendacionesComponent from "../base/recomendaciones/recomendaciones";
import tomaMuestrasComponent from "../base/baseTomaMuestras/indexTomaMuestras";
import FinalizarHistoria from "@/views/historias/base/finalizarHistoria/finalizarHistoria";
import RemisionComponent from "../base/baseRemision/index";

export default {
  components: {
    FinalizarHistoria,
    PageHeaderCompact,
    motivoConsultaComponent,
    WizardTabPane,
    WizardTab,
    Wizard,
    modalInformacionUsuario,
    cardInformacionUsuario,
    resumenHcComponent,
    historialMenstrualComponent,
    intervencionesComponent,
    recomendacionesComponent,
    tomaMuestrasComponent,
    RemisionComponent
  },
  data() {
    return {
      finalizada: "",
      idHistoria: "",
      usuario: {},
      current: 1,
      checkpoint : 1,
      steps: [
        { stepId: "#step1", ref: "motivoConsultaComponent", validate: true },
        { stepId: "#step2", ref: "historialMenstrualComponent", validate: true },
        { stepId: "#step3", ref: "tomaMuestrasComponent", validate: true },
        { stepId: "#step4", ref: "resumenHcComponent", validate: false },
      ],
    };
  },
  methods: {
    prev(step) {

      this.current = step.current;
      this.activeTab();
      
    },
    async next(step) {
      
      if(step.prev <= this.checkpoint && step.current-1 > step.prev){
        this.activeTab();
        return;
      }
      
      const refComponent = this.steps[step.prev - 1].ref;
      
      if (typeof this.$refs[refComponent].save !== "undefined") {
        await this.$refs[refComponent].save();
      }

      if (this.steps[step.prev - 1].validate) {
        if (this.$refs[refComponent].$v.$invalid) return;
      }

      if(step.current > this.checkpoint) {
        historiaClinicaService.updateCheckpoint(this.idHistoria, step.current);
        this.checkpoint = step.current;
      }

      this.current = step.current;

      this.activeTab();
    },
    async cargar(id) {
      
      const response = await historiaClinicaService.show(id);
      
      this.usuario = response.data.usuario;
      this.finalizada = response.data.finalizada;
      this.current = response.data.checkpoint;
      this.checkpoint = response.data.checkpoint;

      this.activeTab()

    },
    activeTab(){
      document.querySelectorAll(`a.active`).forEach(a =>{ a.classList.remove('active') });
      document.querySelector(`a[href='${this.steps[this.current - 1].stepId}']`).classList.add("active");
    }
  },
  created() {
    this.idHistoria = this.$route.params.id;
    this.cargar(this.idHistoria);
  },
  computed: {
    currentProperties() {
      if (this.currentComponent === "motivoConsultaComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
      
      if (this.currentComponent === "historialMenstrualComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario:this.usuario.id,
        }
      }

       if (this.currentComponent === "tomaMuestrasComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario:this.usuario.id,
        }
      }
     
     
      if (this.currentComponent === "resumenHcComponent") {
        return {
          idHistoria: this.idHistoria,
          idUsuario:this.usuario.id,
        }
      }

      return {}
    },
    currentComponent() {
      let components = [
        "motivoConsultaComponent",
        "historialMenstrualComponent",
        "tomaMuestrasComponent",
        "resumenHcComponent"
      ];

      return components[this.current - 1];
    },
  },
}
</script>

<style scoped></style>
