<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-stethoscope"> </i> Motivo de Consulta</span> </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4 text-black">
                        Motivo:
                    </div>
                    <div class="col-md-8">
                        {{motivoConsulta.motivo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 text-black">
                        Descripción:
                    </div>
                    <div class="col-md-8">
                        {{motivoConsulta.descripcion}}
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import motivoConsultaService from "../../../../services/motivoConsultaService";
export default {
    props: ['idHistoria'],
    data(){
        return{
            motivoConsulta:{},
        }
    },
    methods: {
        async cargarMotivoConsulta(){
            const response =await motivoConsultaService.showMotivoByHc(this.idHistoria);
            this.motivoConsulta= response.data;
        }
    },
    created() {
        this.cargarMotivoConsulta();
    }
}
</script>

